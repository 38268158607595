import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import {
  fontSizes,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { A, Container, GridContainer, H1, P } from "@util/standard";
import { Query, SanityColumn, Maybe } from "@graphql-types";
import { BlocksContent, Link } from "@sub";

const MainContainer = styled(Container)`
  width: 90%;

  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
    margin: 50px auto 35px auto;
    flex-direction: column;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
    a,
    p {
      font-size: 18px;
      line-height: 100%;
    }
  }
`;

const ContactContainer = styled(GridContainer)`
  width: auto;
  margin: 0;
  padding-top: 30px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 20px;
    display: none;
  }
`;

const Text = styled(P)`
  margin-top: 0px;
`;

const StyledLink = styled(Link)`
  margin-bottom: 15px;
`;

const StyledA = styled(A)`
  font-size: ${fontSizes.p.default}px;
  margin-bottom: 5px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.p.mobile}px;
  }
`;

interface Props {
  data: Maybe<SanityColumn> | undefined;
}

const ContactInfo = ({ data }: Props) => {
  const { sanitySiteConfig }: Query = useStaticQuery(graphql`
    {
      sanitySiteConfig {
        address
        email
        phone
        social {
          socialLinks {
            _key
            title
            linkTo
          }
        }
      }
    }
  `);

  if (sanitySiteConfig == null) {
    return null;
  }

  const { address, email, phone, social } = sanitySiteConfig;

  const SocialLink = ({
    title,
    url,
  }: {
    title: string | null | undefined;
    url: string | null | undefined;
  }) => {
    return <StyledLink newwindow isUnderlined linktext={title} url={url} />;
  };

  return (
    <MainContainer>
      {data && <BlocksContent blocks={data._rawColumnContent} />}
      <ContactContainer repeat={3} mobileRepeat={2}>
        <Container width="180px" margin="0" flexDirection="column">
          <StyledA href={`mailto:${email}`}>{email}</StyledA>
          <StyledA href={`tel:${phone?.replace(/[()]/g, "")}`}>{phone}</StyledA>
          <Text>{address}</Text>
        </Container>
        <Container autoWidthNoMargin flexDirection="column">
          {social?.socialLinks?.map((link) => (
            <SocialLink
              key={link?._key}
              title={link?.title}
              url={link?.linkTo}
            />
          ))}
        </Container>
        <Link
          newwindow
          linktext="Google Map >"
          url="https://g.page/flightdigital?share"
        />
      </ContactContainer>
    </MainContainer>
  );
};

export default ContactInfo;
